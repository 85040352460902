const dashboardRoutes = [
  {
    path: "",
    name: "dashboard",
    component: () => import("@/modules/dashboard/Home.vue"),
    meta: {
      action: 'manage',
      resource: 'scsc',
      layout: "default"
    }
  }
]

export default dashboardRoutes
