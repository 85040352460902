export const endpoint  = {

    /** [Master] NRC */
    nrc: "master/nrc",
    nrc_state: "master/nrc/states",
    nrc_type: "master/nrc/type",
    nrc_township: "master/nrc/township",

    /** Authorization */
    authorization: "authorization/roles",
    login: "auth/login",
    logout: "auth/logout",
    verify_user: "users/verify-user-token",

    /** User Management */
    users: 'users',
    assign_role: 'users/assign-role',

    /**
     * SCSC Modules
    */
    dashbarod: 'scsc/dashboard',

    scscMember : "scsc/members",
    setpassword : "users/set-password",

    /** Case Management */
    case_management: "/case-management",
    case_categories : "/case-management/categories",
    case: "/case-management/cases",
}