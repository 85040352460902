import ContentList from "@/modules/cms/content/list/ContentList";
import NewContent from "@/modules/cms/content/entry/NewContent";
import EditContent from "@/modules/cms/content/entry/EditContent";

const routes = [
  {
    path: "/cms/contents",
    name: "cms-contents",
    children: [
      {
        path: "",
        name: "contentList",
        component: ContentList,
        meta: {
          layout: "default",
          action: "view",
          resource: "scsc-contents",
          title: "Contents",
          breadcrumbs: [{ title: "Content List", routeName: "contentList" }],
        },
      },
      {
        path: "create",
        name: "newContent",
        component: NewContent,
        meta: {
          layout: "default",
          action: "store",
          resource: "scsc-contents",
          title: "Create Content",
          breadcrumbs: [
            { title: "Content List", routeName: "contentList" },
            { title: "Create", routeName: "newContent" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editContent",
        component: EditContent,
        meta: {
          layout: "default",
          action: "update",
          resource: "scsc-contents",
          title: "Edit Content",
          breadcrumbs: [
            { title: "Content List", routeName: "contentList" },
            { title: "Edit", routeName: "editContent" },
          ],
        },
      },
    ],
  },
];

export default routes;
