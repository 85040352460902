<template>
  <div class="card">
    <DataTable
      ref="dt"
      data-key="id"
      sortMode="multiple"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      responsiveLayout="scroll"
      :lazy="true"
      :paginator="true"
      :value="contentList"
      :multiSortMeta="lazyParams.multiSortMeta"
      :totalRecords="totalRecords"
      :rows="10"
      :rowsPerPageOptions="[10, 25, 50]"
      @page="onPage($event)"
      @sort="onSort($event)"
    >
      <template #header>
        <div class="flex flex-wrap justify-content-between align-items-center">
          <h5 class="m-0 md:mb-2 block lg:hidden">Contents</h5>

          <div class="mt-3 md:mt-0 ml-0 lg:ml-auto">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <div class="mt-3 md:mt-0">
                <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="search" placeholder="Keyword Search" />
                </span>

                <router-link :to="{ name: 'newContent' }">
                  <Button
                    v-if="$can('store', 'cms-contents')"
                    label="Create"
                    class="p-button-primary ml-2"
                  />
                </router-link>
              </div>
            </span>
          </div>
        </div>
      </template>

      <template #empty> No content found. </template>
      <template #loading> Loading content data. Please wait. </template>

      <Column
        field="title.en"
        header="Title (English)"
        :sortable="true"
        style="min-width: 300px"
      >
        <template #body="{ data }">
          <router-link :to="{ name: 'editContent', params: { id: data.id } }">
            {{ data.title.en }}
          </router-link>
        </template>
      </Column>

      <Column
        v-for="col of columns"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        :sortable="col.sortable"
        :style="`min-width: ${col.width !== 'default' ? col.width : 'initial'}`"
      />
      <Column field="status" header="Status" :sortable="true">
        <template #body="{ data }">
          <Badge :value="data.status" :severity="data.severity"></Badge>
        </template>
      </Column>
    </DataTable>

    <ConfirmDialog :showHeader="false">
      <template #message="slotProps">
        <div class="text-center w-full">
          <Button
            type="button"
            :icon="slotProps.message.icon"
            class="
              p-button-lg p-button-danger p-button-rounded p-button-outlined
              mt-4
            "
          />
          <h4>{{ slotProps.message.message }}</h4>
        </div>
      </template>
    </ConfirmDialog>
  </div>

  <Loading v-if="loading"></Loading>
</template>
<script>
import { defineComponent } from "vue";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import ConfirmDialog from "primevue/confirmdialog";
import Loading from "@/components/Loading.vue";
import { useContentList } from "@/modules/cms/content/list/useContentList";

export default defineComponent({
  name: "ContentList",
  components: {
    Loading,
    ConfirmDialog,
    DataTable,
    InputText,
    Column,
    Button,
  },
  setup() {
    const {
      dt,
      lazyParams,
      totalRecords,
      contentList,
      loading,
      search,
      columns,
      onPage,
      onSort,
    } = useContentList();

    return {
      dt,
      lazyParams,
      totalRecords,
      contentList,
      loading,
      search,
      columns,
      onPage,
      onSort,
    };
  },
});
</script>