const routes = [
  {
    path: "/permissions",
    name: "permissions",
    children: [
      {
        path: "",
        name: "permissionList",
        component: () =>
          import(
            "@/modules/applicationPermission/permission/list/Permission.vue"
          ),
        meta: {
          resource: "permissions",
          action: "manage",
          layout: "default",
          title: "Permissions",
          breadcrumbs: [
            { title: "Permission List", routeName: "permissionList" },
          ],
        },
      },
      // {
      //   path: "/permissions/create",
      //   name: "newPermission",
      //   component: () =>
      //     import(
      //       "@/modules/applicationPermission/permission/entry/NewPermission.vue"
      //     ),
      //   meta: {
      //     resource: "user",
      //     action: "read",
      //     layout: "default",
      //   },
      // },
      // {
      //   path: "/permissions/:id/edit",
      //   name: "editPermission",
      //   component: () =>
      //     import(
      //       "@/modules/applicationPermission/permission/entry/EditPermission.vue"
      //     ),
      //   meta: {
      //     resource: "user",
      //     action: "edit",
      //     layout: "default",
      //   },
      // },
    ],
  },
];

export default routes;
