import { endpoint } from "@/constants/endpoint";
import api from "@/libs/axios";

const authService = {
  login: async (params) => {
    try {
      const result = await api.post(endpoint.login, params);
      return result.data;
    } catch {
      return null;
    }
  },
  logout: async () => {
    try {
      const result = await api.post(endpoint.logout.logout);
      return result.data;
    } catch {
      return null;
    }
  },
  
  setPassword: async (token, request) => {
    try {
      const result = await api.post(`${endpoint.setpassword}/${token}`, request);
      return result.data;
    } catch(error) {
      return error;
    }
  },

  verifyToken: async (token) => {
    try {
      const result = await api.post(`${endpoint.verify_user}`, token);
      return result.data;
    } catch(error) {
      return error;
    }
  }

};

export { authService };
