import { createRouter, createWebHistory } from "vue-router";

/**
 * Import module's routes
 */
import dashboardRoutes from "@/modules/dashboard/homeRoute";
import authRoutes from "@/modules/auth/authRoute";
import memberRoutes from "@/modules/scsc/member/memberRoute";
import familyMemberRoutes from "@/modules/scsc/familyMember/familyMemberRoute";
import prepaidRoutes from "@/modules/scsc/sales/prepaid/prepaidRoute";
import subscriptionRoutes from "@/modules/scsc/sales/subscription/subscriptionRoute";
import userRoutes from "@/modules/userManagement/users/userRoute";
import categoryRoutes from "@/modules/cms/category/categoryRoute";
import contentRoutes from "@/modules/cms/content/contentRoute";
import permissionRoutes from "@/modules/applicationPermission/permission/permissionRoute";
import roleRoutes from "@/modules/applicationPermission/role/roleRoute";
import caseSettingRoutes from "@/modules/caseManagement/setting/settingRoute";
// import familyMemberRoutes from "@/modules/member/familymember/familyMemberRoute";

import { useAuthStore } from "@/modules/auth/authStore";
import scscDashboardRoutes from "@/modules/scsc/dashboard/scscDashboardRoute";
import caseManagementRoutes from "@/modules/caseManagement/caseManagementRoutes";
import { userAccountRoutes } from "@/modules/user/userRoute";

// import { canNavigate } from "@/libs/acl/routeProtection";

const routes = [
  ...dashboardRoutes,
  ...authRoutes,
  ...memberRoutes,
  ...familyMemberRoutes,
  ...categoryRoutes,
  ...contentRoutes,
  ...prepaidRoutes,
  ...subscriptionRoutes,
  ...memberRoutes,
  ...userRoutes,
  ...permissionRoutes,
  ...roleRoutes,
  ...memberRoutes,
  ...familyMemberRoutes,
  ...scscDashboardRoutes,
  ...caseManagementRoutes,
  ...caseSettingRoutes,
  ...userAccountRoutes,
  {
    path: "/error-404",
    name: "error404",
    component: () => import("@/pages/NotFound.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/not-authorized",
    name: "not-authorized",
    component: () => import("@/pages/Access.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/set-password",
    name: "SetPassword",
    component: () => import("@/pages/SetPassword.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/set-password/success",
    name: "SetPasswordSuccess",
    component: () => import("@/pages/SetPasswordSuccess.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/:catchAll(.*)*",
    redirect: "error-404",
    meta: {
      layout: "full",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  linkActiveClass: "router-link-active",
  linkExactActiveClass: "router-link-exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  const authStore = useAuthStore();
  const isLoggedIn = authStore.isAuth;
  // console.log(to.name)
  if (
    !isLoggedIn &&
    to.name !== "login" &&
    to.name !== "SetPassword" &&
    to.name !== "SetPasswordSuccess"
  )
    return next({ name: "login" });

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: "login" });

  //   // If logged in => not authorized
  //   return next({ name: "not-authorized" });
  // }
  // // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next({ name: "dashboard" });
  }
  return next();
});

export default router;
