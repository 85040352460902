const familyMemberRoute = [
  {
    path: "/members/:id/family-member",
    name: "scsc-family-member",
    children: [
      {
        path: "create",
        name: "CreateFamilyMember",
        component: () =>
          import("@/modules/scsc/familyMember/entry/CreateFamilyMember.vue"),
        meta: {
          resource: "scsc-prepaid-packages",
          action: "store",
          layout: "default",
          title: "Create Family Member",
          breadcrumbs: [
            { title: "Member List", routeName: "MemberRegistered" },
            // {
            //   title: "Member Detail",
            //   // routeName: "MemberDetail",
            //   params: { id: ":id", name: "account", type: "me" },
            // },
            { title: "Create", routeName: "CreateFamilyMember" },
          ],
        },
      },
      {
        path: ":familyMemberId/edit",
        name: "EditFamilyMember",
        component: () =>
          import("@/modules/scsc/familyMember/entry/EditFamilyMember.vue"),
        meta: {
          resource: "scsc-prepaid-packages",
          action: "store",
          layout: "default",
          title: "Edit Family Member",
          breadcrumbs: [
            { title: "Member List", routeName: "MemberRegistered" },
            // {
            //   title: "Member Detail",
            //   params: { id: ":id", name: "account", type: "me" },
            // },
            { title: "Edit", routeName: "EditFamilyMember" },
          ],
        },
      },
    ],
  },
];

export default familyMemberRoute;
