import { endpoint } from "@/constants/endpoint"
import api from "@/libs/axios"

export const nrcService = {
    nrcList: async () => {
        const response =  await api.get(endpoint.nrc);
        if(response && response.data) {
            return response.data.data;
        }

        return [];
    }
}