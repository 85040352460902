import { defineStore } from "pinia";
import { authService } from "./authService";
import router from "@/routers";
import { initialAbility } from "@/libs/acl/config";
import ability from "@/libs/acl/ability"

export const useAuthStore = defineStore({
  id: "useAuthStore",
  state: () => ({
    token: localStorage.getItem("userToken") || null,
    userData: localStorage.getItem("userData") || null,
    loginResponse: null,
  }),
  getters: {
    getToken(state) {
      return state.token;
    },
    getUserData(state) {
      return state.userData ? JSON.parse(state.userData) : null;
    },
    getLoginResponse(state) {
      return state.loginResponse;
    },
    isAuth(state) {
      return state.token ? true : false;
    },
  },

  actions: {
    async login(params) {
      const response = await authService.login(params);
      this.loginResponse = response;
      if (response) {
        var responseAbility = response.data.user.allowed_permissions;
        var currentInitialAbility = initialAbility;
        var userAbility = responseAbility.concat(currentInitialAbility);
        var encodeUserAbility = JSON.stringify(userAbility);

        localStorage.setItem("probability", encodeUserAbility);
        //update abality
        ability.update(JSON.parse(encodeUserAbility))

        localStorage.setItem("userData", JSON.stringify(response.data.user));
        localStorage.setItem("userToken", response.data.access_token);
        
        this.token = response.data.access_token;
        this.userData = JSON.stringify(response.data.user);
      }
    },
    async logout() {
      localStorage.removeItem("userToken");
      localStorage.removeItem("userData");
      localStorage.removeItem("probability");
      this.token = null;
      this.userData = null;
      router.push({ name: "login" });
    },
  },
});
