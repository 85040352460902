const routes = [
  {
    path: "/scsc/subscriptions",
    name: "scsc-subscription",
    children: [
      {
        path: "",
        name: "subscriptionList",
        component: () =>
          import("@/modules/scsc/sales/subscription/list/Subscriptions.vue"),
        meta: {
          layout: "default",
          resource: "scsc-subscriptions",
          action: "index",
          title: "Subscriptions",
          breadcrumbs: [
            { title: "Subscription List", routeName: "subscriptionList" },
          ],
        },
      },
      {
        path: "create",
        name: "newSubscription",
        component: () =>
          import("@/modules/scsc/sales/subscription/entry/NewSubscription.vue"),
        meta: {
          layout: "default",
          resource: "scsc-subscriptions",
          action: "store",
          title: "Create Subscription",
          breadcrumbs: [
            { title: "Subscription List", routeName: "subscriptionList" },
            { title: "Create", routeName: "newSubscription" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editSubscription",
        component: () =>
          import(
            "@/modules/scsc/sales/subscription/entry/EditSubscription.vue"
          ),
        meta: {
          layout: "default",
          resource: "scsc-subscriptions",
          action: "update",
          title: "Edit Subscription",
          breadcrumbs: [
            { title: "Subscription List", routeName: "subscriptionList" },
            { title: "Edit", routeName: "newSubscription" },
          ],
        },
      },
    ],
  },
];

export default routes;
