const routes = [
  {
    path: "/scsc/prepaid-packages",
    name: "scsc-prepaid-package",
    children: [
      {
        path: "",
        name: "prepaidList",
        component: () =>
          import("@/modules/scsc/sales/prepaid/list/Prepaids.vue"),
        meta: {
          resource: "scsc-prepaid-packages",
          action: "index",
          layout: "default",
          title: "Prepaid Packages",
          breadcrumbs: [{ title: "Prepaid List", routeName: "prepaidList" }],
        },
      },
      {
        path: "create",
        name: "newPrepaid",
        component: () =>
          import("@/modules/scsc/sales/prepaid/entry/NewPrepaid.vue"),
        meta: {
          resource: "scsc-prepaid-packages",
          action: "store",
          layout: "default",
          title: "Create Prepaid Package",
          breadcrumbs: [
            { title: "Prepaid List", routeName: "prepaidList" },
            { title: "Create", routeName: "newPrepaid" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editPrepaid",
        component: () =>
          import("@/modules/scsc/sales/prepaid/entry/EditPrepaid.vue"),
        meta: {
          resource: "scsc-prepaid-packages",
          action: "update",
          layout: "default",
          title: "Edit Prepaid Package",
          breadcrumbs: [
            { title: "Prepaid List", routeName: "prepaidList" },
            { title: "Edit", routeName: "editPrepaid" },
          ],
        },
      },
    ],
  },
];

export default routes;
