import api from "@/libs/axios";
import qs from "qs";

const endpoints = {
  cms: `/cms/contents`,
};

const useContentService = {
  fetchAllContent: async (params) => {
    try {
      const result = await api.get(`${endpoints.cms}`, {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });

      return result.data;
    } catch {
      return null;
    }
  },

  fechAddContent: async (params) => {
    const headers = { "Content-Type": "multipart/form-data" };
    const result = await api.post(`${endpoints.cms}`, params, headers);
    return result.data;
  },

  fetchEditContent: async (params) => {
    try {
      const result = await api.get(`${endpoints.cms}/${params.id}`);
      return result.data;
    } catch {
      return null;
    }
  },

  fetchUpdateContent: async (id, params) => {
    const headers = { "Content-Type": "multipart/form-data" };
    const result = await api.post(`${endpoints.cms}/${id}`, params, headers);
    return result.data;
  },

  fetchDeleteContent: async (params) => {
    try {
      const result = await api.delete(`${endpoints.cms}/${params.id}`);
      return result.data;
    } catch {
      return null;
    }
  },
};

export { useContentService };
