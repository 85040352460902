const memberRoutes = [
  {
    path: "/members",
    name: "scsc-member",
    children: [
      {
        path: "",
        name: "MemberRegistered",
        component: () =>
          import("@/modules/scsc/member/view/MemberRegistered.vue"),
        meta: {
          resource: "scsc-users",
          action: "index",
          layout: "default",
          title: "SCSC Members List",
          breadcrumbs: [
            { title: "Member List", routeName: "MemberRegistered" },
          ],
        },
      },
      {
        path: ":id/:name/:type",
        name: "MemberDetail",
        component: () => import("@/modules/scsc/member/view/MemberDetail.vue"),
        meta: {
          resource: "scsc-users",
          action: "index",
          layout: "default",
          title: "Member Detail",
          breadcrumbs: [
            { title: "Member List", routeName: "MemberRegistered" },
            { title: "Member Detail", routeName: "MemberDetail" },
          ],
        },
      },
    ],
  },
  {
    path: "/pending-members",
    name: "scsc-pending-member",
    children: [
      {
        path: "",
        name: "PendingMember",
        component: () => import("@/modules/scsc/member/view/PendingMember.vue"),
        meta: {
          resource: "scsc-users",
          action: "index",
          layout: "default",
          title: "SCSC Pending Members List",
          breadcrumbs: [{ title: "Member List", routeName: "PendingMember" }],
        },
      },
    ],
  },
];

export default memberRoutes;
