import { ref, onMounted, watch } from "vue";
import { useContentStore } from "@/modules/cms/content/contentStore";
import { useDebounceFn } from "@/utils/debounce";
import { multisortConvert } from "@/utils/multisort";

export const useContentList = () => {
  const loading = ref(true);
  const store = useContentStore();

  const dt = ref();
  const lazyParams = ref({});
  const contentList = ref([]);
  const totalRecords = ref(10);
  const search = ref("");

  const columns = [
    { field: "title.mm", header: "Title (Myanmar)", width: "300px" },
    { field: "category_title", header: "Category", sortable: true, width: "200px" },
  ];

  onMounted(() => {
    resetPagination();
    fetchContentList();
  });

  const fetchContentList = async () => {
    loading.value = true;

    await store.listContentAction({
      page: (lazyParams.value.page += 1),
      per_page: lazyParams.value.rows,
      order: multisortConvert(lazyParams.value.multiSortMeta),
      search: search.value,
    });

    const response = store.getAllContent;

    if (response) {
      contentList.value = response.data.data;
      totalRecords.value = response.data.total;
      contentList.value.map((content) => {
        content.severity = content.status === "ACTIVE" ? "success" : "danger";
        return content;
      })
    }

    loading.value = false;
  };

  const resetPagination = () => {
    lazyParams.value = {
      page: 0,
      rows: dt.value.rows,
      multiSortMeta: [],
    };
  };

  const onPage = (event) => {
    lazyParams.value = event;
    lazyParams.value.multiSortMeta = [];
    fetchContentList();
  };

  const onSort = (event) => {
    lazyParams.value = event;
    lazyParams.value.page = 0;
    fetchContentList();
  };

  watch(
    [search],
    useDebounceFn(() => {
      resetPagination();
      fetchContentList();
    }, 500)
  );

  return {
    dt,
    lazyParams,
    totalRecords,
    contentList,
    loading,
    store,
    search,
    columns,
    onPage,
    onSort,
  };
};
