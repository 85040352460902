export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မြန်မာ"])},
  "scsc": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCSC"])}
  },
  "dashboard": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "sales": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])}
  },
  "prepaid_packages": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepaid Packages"])}
  },
  "subscriptions": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])}
  },
  "members": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])}
  },
  "pending_members": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Members"])}
  },
  "cms": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMS"])}
  },
  "categories": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])}
  },
  "contents": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents"])}
  },
  "user": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
  },
  "users": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
  },
  "permission": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])}
  },
  "roles": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])}
  },
  "permissions": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])}
  },
  "case_management": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case Management"])}
  },
  "cases": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cases"])}
  },
  "cases_settings": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  }
}