import { defineStore } from "pinia";
import { useCategoryService } from "@/modules/cms/category/categoryService";

export const useCategoryStore = defineStore({
  id: "useCategoryStore",
  state: () => ({
    listCategoryState: null,
    addCategoryState: null,
    editCategoryState: null,
    updateCategoryState: null,
    deleteCategoryState: null,
  }),

  getters: {
    getAllCategory(state) {
      return state.listCategoryState;
    },

    // getAddCategory(state) {
    //   return state.addCategoryState;
    // },

    // getEditCategory(state) {
    //   return state.editCategoryState;
    // },

    // getUpdateCategory(state) {
    //   return state.updateCategoryState;
    // },

    // getDeleteCategory(state) {
    //   return state.deleteCategoryState;
    // },
  },

  actions: {
    async allCategoryAction(params) {
      const response = await useCategoryService.fetchAllCategory(params);
      this.listCategoryState = response;
    },

    // async addCategoryAction(params) {
    //   const response = await useCategoryService.fechCreateCategory(params);
    //   this.addCategoryState = response;
    // },

    // async editCategoryAction(params) {
    //   const response = await useCategoryService.fetchShowCategory(params);
    //   this.editCategoryState = response;
    // },

    // async updateCategoryAction(params) {
    //   const response = await useCategoryService.fetchUpdateCategory(params);
    //   this.updateCategoryState = response;
    // },

    // async deleteCategoryAction(params) {
    //   const response = await useCategoryService.fetchDeleteCategory(params);
    //   this.deleteCategoryState = response;
    // },
  },
});
