const routes = [
  {
    path: "/roles",
    name: "roles",
    children: [
      {
        path: "",
        name: "roleList",
        component: () =>
          import("@/modules/applicationPermission/role/list/Role.vue"),
        meta: {
          resource: "roles",
          action: "manage",
          layout: "default",
          title: "Roles",
          breadcrumbs: [{ title: "Role List", routeName: "roleList" }],
        },
      },
      {
        path: "create",
        name: "newRole",
        component: () =>
          import("@/modules/applicationPermission/role/entry/NewRole.vue"),
        meta: {
          resource: "roles",
          action: "manage",
          layout: "default",
          title: "Create Role",
          breadcrumbs: [
            { title: "Role List", routeName: "roleList" },
            { title: "Create", routeName: "newRole" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editRole",
        component: () =>
          import("@/modules/applicationPermission/role/entry/EditRole.vue"),
        meta: {
          resource: "roles",
          action: "manage",
          layout: "default",
          title: "Edit Role",
          breadcrumbs: [
            { title: "Role List", routeName: "roleList" },
            { title: "Edit", routeName: "newRole" },
          ],
        },
      },
    ],
  },
];

export default routes;
