const routes = [
  {
    path: "/setting",
    name: "working-days-and-holidays",
    children: [
      {
        path: "working-days-and-holidays",
        name: "workingDaysAndHolidaysList",
        component: () =>
          import(
            "@/modules/caseManagement/setting/entry/EditWorkingDayAndHoliday.vue"
          ),
        meta: {
          resource: "settings",
          action: "manage",
          layout: "default",
          title: "Working Days And Holidays",
          breadcrumbs: [
            { title: "Setting", routeName: "workingDaysAndHolidaysList" },
          ],
        },
      },
    ],
  },
];

export default routes;
