const caseManagementRoutes = [
  {
    path: "/case-management/categories",
    name: "case-management-categories",
    children: [
      {
        path: "",
        name: "CaseCategoryList",
        component: () =>
          import("@/modules/caseManagement/list/CaseCategoryList.vue"),
        meta: {
          resource: "case-management",
          action: "index",
          layout: "default",
          title: "Case Categories",
          breadcrumbs: [
            { title: "Case Category List", routeName: "CaseCategoryList" },
          ],
        },
      },
      {
        path: "create",
        name: "CreateCaseCategories",
        component: () =>
          import("@/modules/caseManagement/entry/CreateCaseCategories.vue"),
        meta: {
          resource: "case-management",
          action: "create",
          layout: "default",
          title: "Create Case Category",
          breadcrumbs: [
            { title: "Case Category List", routeName: "CaseCategoryList" },
            { title: "Create", routeName: "CreateCaseCategories" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "UpdateCaseCategories",
        component: () =>
          import("@/modules/caseManagement/entry/UpdateCaseCategories.vue"),
        meta: {
          resource: "case-management",
          action: "update",
          layout: "default",
          title: "Edit Case Category",
          breadcrumbs: [
            { title: "Case Category List", routeName: "CaseCategoryList" },
            { title: "Edit", routeName: "UpdateCaseCategories" },
          ],
        },
      },
    ],
  },
  {
    path: "/case-management/dashboard",
    name: "CaseManagementDashboard",
    component: () =>
      import("@/modules/caseManagement/view/Dashboard"),
    meta: {
      resource: "case-management",
      action: "index",
      layout: "default",
      title: "Case Dashboard",
      breadcrumbs: [
        { title: "Case Dashboard", routeName: "CaseManagementDashboard" },
      ],
    },
  },
  {
    path: "/case-management/cases",
    name: "case-management-cases",
    children: [
      {
        path: "",
        name: "CaseList",
        component: () => import("@/modules/caseManagement/list/CaseList.vue"),
        meta: {
          resource: "case-management",
          action: "index",
          layout: "default",
          title: "Cases",
          breadcrumbs: [{ title: "Case List", routeName: "CaseList" }],
        },
      },
      {
        path: ":id/edit",
        name: "UpdateCase",
        component: () =>
          import("@/modules/caseManagement/entry/UpdateCase.vue"),
        meta: {
          resource: "case-management",
          action: "index",
          layout: "default",
          title: "Edit Case",
          breadcrumbs: [
            { title: "Case List", routeName: "CaseList" },
            { title: "Edit", routeName: "UpdateCase" },
          ],
        },
      },
    ],
  },
];

export default caseManagementRoutes;
