import axios from "axios";
import EventBus from "@/libs/AppEventBus";
import { useAuthStore } from "@/modules/auth/authStore";
import router from "@/routers";

const api = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

api.interceptors.request.use(function (config) {
  const store = useAuthStore();
  const token = store.getToken;
  config.headers.common["Authorization"] = `Bearer ${token}`;
  return config;
});

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        const store = useAuthStore();
        store.logout();

        EventBus.emit("show-toast", {
          severity: "error",
          summary: "",
          detail: err.response.data.message,
        });
      } else if (err.response.status === 403) {
        router.push({ name: "not-authorized" });
      } else if (err.response.status === 422) {
        return Promise.reject(err.response);
      } else {
        EventBus.emit("show-toast", {
          severity: "error",
          summary: "",
          detail: err.response.data.message,
        });
      }
      /**
       * Can see EventBus in AppWrapper(Top Component)
       */
    } else if (err.request) {
      EventBus.emit("show-toast", {
        severity: "error",
        summary: "",
        detail: err.message,
      });
    } else {
      EventBus.emit("show-toast", {
        severity: "error",
        summary: "",
        detail: err.message,
      });
    }
  }
);

export default api;
