const routes = [
  {
    path: "/users",
    name: "scsc-users",
    children: [
      {
        path: "",
        name: "userList",
        component: () =>
          import("@/modules/userManagement/users/list/Users.vue"),
        meta: {
          resource: "scsc-users",
          action: "index",
          layout: "default",
          title: "Users",
          breadcrumbs: [{ title: "User List", routeName: "userList" }],
        },
      },
      {
        path: "create",
        name: "newUser",
        component: () =>
          import("@/modules/userManagement/users/entry/NewUser.vue"),
        meta: {
          resource: "scsc-users",
          action: "create",
          layout: "default",
          title: "Create User",
          breadcrumbs: [
            { title: "User List", routeName: "userList" },
            { title: "Create", routeName: "newUser" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editUser",
        component: () =>
          import("@/modules/userManagement/users/entry/EditUser.vue"),
        meta: {
          resource: "scsc-users",
          action: "update",
          layout: "default",
          title: "Edit User",
          breadcrumbs: [
            { title: "User List", routeName: "userList" },
            { title: "Edit", routeName: "newUser" },
          ],
        },
      },
    ],
  },
];

export default routes;
