const scscDashboardRoutes = [
    {
      path: "/scsc/dashboard",
      name: "scscDashboard",
      component: () => import("@/modules/scsc/dashboard/view/scscDashboard.vue"),
      meta: {
        layout: "default"
      }
    }
  ]
  
  export default scscDashboardRoutes;
  