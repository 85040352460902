import { reactive, ref, onMounted } from "vue";
import { useContentStore } from "@/modules/cms/content/contentStore";
import { useCategoryStore } from "@/modules/cms/category/categoryStore";
import { useRouter } from "vue-router";
import EventBus from "@/libs/AppEventBus";
import { Errors } from "@/utils/validation";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { dateShort } from "@/utils/formatter";

export const useNewContent = () => {
  const contentStore = useContentStore();
  const categoryStore = useCategoryStore();
  const router = useRouter();

  const isLoading = ref(false);
  const submitted = ref(false);
  const errors = new Errors();
  const showCropDialog = ref(false);
  const crop_photo = ref(null);
  const photo = ref(null);
  const fileUrl = ref("");
  const optionsCategory = ref([]);

  const state = reactive({
    titleEn: "",
    titleMm: "",
    status: "",
    categoryId: null,
    contentEn: "",
    contentMm: "",
    published_at: null,
    is_notifiable: true,
    has_notified: false
  });

  const optionsStatus = ref([
    { name: "Active", code: "ACTIVE" },
    { name: "Disabled", code: "DISABLED" },
  ]);

  const categoryData = async () => {
    isLoading.value = true;

    try {
      await categoryStore.allCategoryAction();

      const response = categoryStore.getAllCategory;

      if (response) {
        optionsCategory.value = response.data;
      }

      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      if (error.status === 422) {
        const err = error.data.data;
        errors.record(err);
      }
    }
  };

  onMounted(() => {
    categoryData();
  });

  const handleSubmit = () => {
    addContent();
  };

  const addContent = async () => {
    errors.clear();
    isLoading.value = true;

    const content = JSON.stringify({
      en: state.contentEn,
      mm: state.contentMm,
    });

    const title = JSON.stringify({
      en: state.titleEn,
      mm: state.titleMm
    });

    var formData = new FormData();
    if (fileUrl.value.length != 0) {
      formData.append("image", fileUrl.value);
    }

    console.log(state.is_notifiable);

    formData.append("title", title);
    formData.append("content", content);
    formData.append("category_id", state.categoryId);
    formData.append("status", state.status);
    formData.append("published_at", dateShort(state.published_at) || null);
    formData.append("is_notifiable", state.is_notifiable ? 1 : 0);
    formData.append("has_notified", state.has_notified ? 1 : 0);
    
    try {
      await contentStore.addContentAction(formData);

      const response = contentStore.getAddContent;

      if (response) {
        router.push({ name: "contentList" });
        EventBus.emit("show-toast", {
          severity: "success",
          summary: "",
          detail: response.message,
        });
      }

      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      if (error.status === 422) {
        const err = error.data.data;
        errors.record(err);
      }
    }
  };

  const takePicture = async () => {
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      photo.value = image.webPath;
      showCropDialog.value = true;
    } catch (err) {
      console.log(err.message);
    }
  };

  const cropImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_photo.value = canvas.toDataURL("image/jpeg");
    canvas.toBlob(function (blob) {
      fileUrl.value = blob;
    });
  };

  return {
    isLoading,
    state,
    submitted,
    errors,
    handleSubmit,
    optionsStatus,
    optionsCategory,
    crop_photo,
    cropImage,
    showCropDialog,
    takePicture,
    photo,
  };
};
