const menuList = [
  {
    // label: "",
    // icon: "pi pi-fw pi-search",
    items: [
      // {
      //   label: "Home",
      //   icon: "",
      //   items: [
      //     {
      //       label: "Dashboard",
      //       icon: "pi pi-fw pi-home",
      //       to: "/",
      //       action: "read",
      //       resource: "home",
      //     },
      //   ],
      // },
      {
        label: "scsc.menu",
        icon: "ri-price-tag-3-line ri-lg",
        action: "manage",
        resource: "scsc",
        items: [
          {
            label: "dashboard.menu",
            icon: "ri-function-line",
            to: "/scsc/dashboard",
            action: "manage",
            resource: "scsc",
          },
          {
            label: "sales.menu",
            icon: "ri-pie-chart-line",
            action: "manage",
            resource: "scsc",
            items: [
              {
                label: "subscriptions.menu",
                icon: "ri-ticket-line",
                to: "/scsc/subscriptions",
                action: "index",
                resource: "scsc-subscriptions",
              },
              {
                label: "prepaid_packages.menu",
                icon: "ri-ticket-line",
                to: "/scsc/prepaid-packages",
                action: "index",
                resource: "scsc-prepaid-packages",
              },
            ],
          },
          {
            label: "members.menu",
            icon: "ri-team-line ri-lg",
            action: "manage",
            resource: "scsc",
            items: [
              {
                label: "members.menu",
                icon: "ri-user-follow-line",
                to: "/members",
                action: "manage",
                resource: "scsc",
              },
              // {
              //   label: "Registered Members",
              //   icon: "pi pi-fw pi-users",
              //   to: "/registered-members",
              //   action: "read",
              //   resource: "user",
              // },
              {
                label: "pending_members.menu",
                icon: "ri-user-unfollow-line",
                to: "/pending-members",
                action: "manage",
                resource: "scsc",
              },
            ],
          },
        ],
      },
      {
        label: "cms.menu",
        icon: "ri-file-list-3-line ri-lg",
        action: "manage",
        resource: "cms",
        items: [
          {
            label: "categories.menu",
            icon: "ri-stack-line",
            to: "/cms/categories",
            action: "index",
            resource: "cms-categories",
          },
          {
            label: "contents.menu",
            icon: "ri-edit-box-line",
            to: "/cms/contents",
            action: "index",
            resource: "cms-contents",
          },
        ],
      },
      {
        label: "user.menu",
        icon: "ri-group-line ri-lg",
        action: "index",
        resource: "users",
        items: [
          {
            label: "users.menu",
            icon: "ri-user-settings-line",
            to: "/users",
            action: "index",
            resource: "users",
          },
        ],
      },
      {
        label: "permission.menu",
        icon: "ri-shield-check-line ri-lg",
        action: "manage",
        resource: "roles-permissions",
        items: [
          {
            label: "roles.menu",
            icon: "ri-shield-user-line",
            to: "/roles",
            action: "manage",
            resource: "roles",
          },
          {
            label: "permissions.menu",
            icon: "ri-shield-user-line",
            to: "/permissions",
            action: "manage",
            resource: "permissions",
          },
        ],
      },
      {
        label: "case_management.menu",
        icon: "ri-price-tag-3-line ri-lg",
        action: "index",
        resource: "cases",
        items: [
          {
            label: "dashboard.menu",
            icon: "ri-function-line",
            to: "/case-management/dashboard",
            action: "index",
            resource: "cases",
          },
          {
            label: "categories.menu",
            icon: "ri-list-check-2",
            to: "/case-management/categories",
            action: "index",
            resource: "case-categories",
          },
          {
            label: "cases.menu",
            icon: "ri-briefcase-3-line",
            to: "/case-management/cases",
            action: "index",
            resource: "cases",
          },
          {
            label: "cases_settings.menu",
            icon: "ri-settings-2-line",
            to: "/setting/working-days-and-holidays",
            action: "manage",
            resource: "settings",
          },
        ],
      },
    ],
  },
];

export default menuList;
