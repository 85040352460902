<template>
  <div class="card">
    <div class="flex flex-wrap justify-content-between align-items-center">
      <h5 class="m-0 md:mb-2 block lg:hidden">Edit Content</h5>

      <div class="mt-3 md:mt-0 ml-0 lg:ml-auto">
        <Button
          v-if="$can('delete', 'cms-contents')"
          type="button"
          icon="pi pi-trash"
          class="p-button-danger p-button-outlined"
          @click="showConfirmDialog(state.id)"
        />
      </div>
    </div>
    <form class="p-fluid" @submit.prevent="handleSubmit()">
      <div class="grid">
        <!-- Photo -->
        <label class="col-12 m-0 text-center font-bold">Photo</label>
        <div class="col-12 mb-4">
          <div class="custom-upload text-center" @click="takePicture">
            <img
              v-if="crop_photo"
              :src="crop_photo"
              class="member-profile"
              alt="profile"
            />
            <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
          </div>
          <small v-if="errors.has('image')" class="p-error">
            <div
              v-for="error in errors.get('image')"
              :key="error"
              class="text-center"
            >
              {{ error }}
            </div>
          </small>
        </div>
        <!-- Phooto -->

        <div class="field col-12 md:col-6 lg:col-6">
          <label for="name" class="font-bold"> Title (English)* </label>
          <InputText
            id="name"
            v-model="state.title.en"
            placeholder="Content title (english)"
            class="w-full"
          />
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <label for="name" class="font-bold"> Title (Myanmar)* </label>
          <InputText
            id="name"
            v-model="state.title.mm"
            placeholder="Content title (Myanmar)"
            class="w-full"
          />

          <small v-if="errors.has('title')" class="p-error">
            <div v-for="error in errors.get('title')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <label for="category" class="font-bold">Category*</label>

          <Dropdown
            id="category"
            v-model="state.categoryId"
            :options="optionsCategory"
            optionLabel="title"
            optionValue="id"
            placeholder="Select Category"
            class="w-full"
          />

          <small v-if="errors.has('category_id')" class="p-error">
            <div v-for="error in errors.get('category_id')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <label for="status" class="font-bold">Status</label>

          <Dropdown
            id="status"
            v-model="state.status"
            :options="optionsStatus"
            optionLabel="name"
            optionValue="code"
            placeholder="Select Status"
            class="w-full"
          />
          <small v-if="errors.has('status')" class="p-error">
            <div v-for="error in errors.get('status')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <label for="entries" class="font-bold">Content EN</label>
          <Editor v-model="state.contentEn" editorStyle="height: 320px">
            <template #toolbar>
              <span class="ql-formats">
                <select class="ql-header" defaultValue="0">
                  <option value="1">Heading</option>
                  <option value="2">Subheading</option>
                  <option value="0">Normal</option>
                </select>
                <select class="ql-font">
                  <option></option>
                  <option value="serif"></option>
                  <option value="monospace"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-bold" type="button"></button>
                <button class="ql-italic" type="button"></button>
                <button class="ql-underline" type="button"></button>
              </span>
              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>
              <span class="ql-formats">
                <button class="ql-list" value="ordered" type="button"></button>
                <button class="ql-list" value="bullet" type="button"></button>
                <select class="ql-align">
                  <option defaultValue></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-link" type="button"></button>
                <button class="ql-code-block" type="button"></button>
              </span>
            </template>
          </Editor>
          <small v-if="errors.has('content')" class="p-error">
            <div v-for="error in errors.get('content')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <label for="entries" class="font-bold">Content MM</label>
          <Editor v-model="state.contentMm" editorStyle="height: 320px">
            <template #toolbar>
              <span class="ql-formats">
                <select class="ql-header" defaultValue="0">
                  <option value="1">Heading</option>
                  <option value="2">Subheading</option>
                  <option value="0">Normal</option>
                </select>
                <select class="ql-font">
                  <option></option>
                  <option value="serif"></option>
                  <option value="monospace"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-bold" type="button"></button>
                <button class="ql-italic" type="button"></button>
                <button class="ql-underline" type="button"></button>
              </span>
              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>
              <span class="ql-formats">
                <button class="ql-list" value="ordered" type="button"></button>
                <button class="ql-list" value="bullet" type="button"></button>
                <select class="ql-align">
                  <option defaultValue></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-link" type="button"></button>
                <button class="ql-code-block" type="button"></button>
              </span>
            </template>
          </Editor>
          <small v-if="errors.has('content')" class="p-error">
            <div v-for="error in errors.get('content')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>
        <div class="field col-12 md:col-4 lg:col-4">
          <label class="font-bold"> Published At </label>
          <Calendar
            class="w-full"
            dateFormat="dd-mm-yy"
            v-model="state.published_at"
            placeholder="Choose published Date"
          />
        </div>

        <div
          class="
            field
            col-12
            md:col-4
            lg:col-4
            flex flex-row
            align-items-center
            h-7rem
          "
        >
          <Checkbox
            id="is_notifiable"
            class="mr-3"
            inputId="binary"
            :binary="true"
            v-model="state.is_notifiable"
          />
          <label class="font-bold mt-2" for="is_notifiable">
            Is Notifiable
          </label>
        </div>

        <div
          class="
            field
            col-12
            md:col-4
            lg:col-4
            flex flex-row
            align-items-center
            h-7rem
          "
        >
          <Checkbox
            id="has_notified"
            class="mr-3"
            inputId="binary"
            :binary="true"
            v-model="state.has_notified"
          />
          <label class="font-bold mt-2" for="has_notified">
            Has Notified
          </label>
        </div>
      </div>

      <div class="col-12 md:col-12 flex justify-content-end">
        <router-link
          v-if="$can('index', 'cms-contents')"
          :to="{ name: 'contentList' }"
        >
          <div><Button label="Cancel" class="mt-2 p-button-outlined" /></div>
        </router-link>

        <div>
          <Button
            v-if="$can('update', 'cms-contents')"
            type="submit"
            label="Update"
            class="ml-2 mt-2"
          />
        </div>
      </div>
    </form>

    <Dialog
      header="Crop Image"
      v-model:visible="showCropDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
    >
      <div class="p-4">
        <cropper
          class="cropper"
          v-if="photo"
          :src="photo"
          :stencil-props="{
            aspectRatio: 1 / 1,
          }"
          @change="cropImage"
        ></cropper>
      </div>
      <template #footer>
        <Button label="Got it" @click="showCropDialog = false" autofocus />
      </template>
    </Dialog>

    <ConfirmDialog :showHeader="false">
      <template #message="slotProps">
        <div class="text-center w-full">
          <Button
            type="button"
            :icon="slotProps.message.icon"
            class="
              p-button-lg p-button-danger p-button-rounded p-button-outlined
              mt-4
            "
          />
          <h4>{{ slotProps.message.message }}</h4>
        </div>
      </template>
    </ConfirmDialog>

    <Loading v-if="isLoading"></Loading>
  </div>
</template>
  
  <script>
// import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Loading from "@/components/Loading.vue";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import ConfirmDialog from "primevue/confirmdialog";
import { Cropper } from "vue-advanced-cropper";
import { defineComponent } from "vue";
import { useEditContent } from "@/modules/cms/content/entry/useEditContent";
// import Quill from "quill";

export default defineComponent({
  name: "EditContent",
  components: {
    Loading,
    Button,
    InputText,
    Dialog,
    Cropper,
    Dropdown,
    Editor,
    ConfirmDialog,
  },
  setup() {
    const {
      isLoading,
      state,
      submitted,
      handleSubmit,
      errors,
      optionsStatus,
      optionsCategory,
      showConfirmDialog,
      crop_photo,
      cropImage,
      showCropDialog,
      takePicture,
      photo,
    } = useEditContent();

    return {
      isLoading,
      state,
      submitted,
      handleSubmit,
      errors,
      optionsStatus,
      optionsCategory,
      showConfirmDialog,
      crop_photo,
      cropImage,
      showCropDialog,
      takePicture,
      photo,
    };
  },
});

// var toolbarOptions = ["bold", "italic", "underline", "strike"];

// var quill = new Quill("#editor", {
//   modules: {
//     toolbar: toolbarOptions,
//   },
// });
</script>
  <style lang="scss" scoped>
.card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
    