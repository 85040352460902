import "@/plugins/global-css";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import TabMenu from "primevue/tabmenu";
import { Cropper } from "vue-advanced-cropper";
import Card from "primevue/card";
import Badge from "primevue/badge";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import InputMask from "primevue/inputmask";
import MultiSelect from "primevue/multiselect";
import TextArea from "primevue/textarea";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Loading from "@/components/Loading.vue";
import ConfirmDialog from "primevue/confirmdialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";

import { app } from "@/plugins/main-app";

import "@/plugins/abality";
import "@/plugins/confirmation-service";
import "@/plugins/global-components";
import "@/plugins/i18n";
import "@/plugins/moment";
import "@/plugins/pinia";
import "@/plugins/primevue";
import "@/plugins/router";
import "@/plugins/toast-service";
import "@/plugins/theme";
import "./assets/styles/app.css";

app.component("Card", Card);
app.component("Badge", Badge);
app.component("InputText", InputText);
app.component("Calendar", Calendar);
app.component("Dropdown", Dropdown);
app.component("InputMask", InputMask);
app.component("TextArea", TextArea);
app.component("Button", Button);
app.component("Dialog", Dialog);
app.component("Cropper", Cropper);
app.component("Loading", Loading);
app.component("ConfirmDialog", ConfirmDialog);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("MultiSelect", MultiSelect);
app.component("TabMenu", TabMenu);
app.component("Checkbox", Checkbox);

app.mount("#app");
defineCustomElements(window);
