import { onMounted, reactive, ref } from "vue";
import { useContentStore } from "@/modules/cms/content/contentStore";
import { useCategoryStore } from "@/modules/cms/category/categoryStore";
import { useRoute, useRouter } from "vue-router";
import EventBus from "@/libs/AppEventBus";
import { useConfirm } from "primevue/useconfirm";
import { Errors } from "@/utils/validation";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { dateShort } from "@/utils/formatter";

export const useEditContent = () => {
  const contentStore = useContentStore();
  const categoryStore = useCategoryStore();
  const route = useRoute();
  const router = useRouter();
  const confirm = useConfirm();

  const isLoading = ref(false);
  const submitted = ref(false);
  const errors = new Errors();
  const showCropDialog = ref(false);
  const crop_photo = ref(null);
  const photo = ref(null);
  const fileUrl = ref("");
  const optionsCategory = ref([]);

  const state = reactive({
    title: "",
    status: "",
    categoryId: null,
    contentEn: "",
    contentMm: "",
    is_notifiable: true,
    has_notified: false,
    published_at: ""
  });

  const optionsStatus = ref([
    { name: "Active", code: "ACTIVE" },
    { name: "Disabled", code: "DISABLED" },
  ]);

  onMounted(() => {
    editContent();
    categoryData();
  });

  const categoryData = async () => {
    isLoading.value = true;

    try {
      await categoryStore.allCategoryAction();

      const response = categoryStore.getAllCategory;

      if (response) {
        optionsCategory.value = response.data;
      }

      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      if (error.status === 422) {
        const err = error.data.data;
        errors.record(err);
      }
    }
  };

  const editContent = async () => {
    isLoading.value = true;

    await contentStore.editContentAction({
      id: route.params.id,
    });

    const response = contentStore.getEditContent;
    if (response) {
      (state.id = route.params.id), (state.name = response.data.name);
      state.title = response.data.title;
      state.contentEn = response.data.content ? response.data.content.en : "";
      state.contentMm = response.data.content ? response.data.content.mm : "";
      state.status = response.data.status;
      state.categoryId = response.data.category_id;
      crop_photo.value = response.data.image_url;
      state.is_notifiable = response.data.is_notifiable;
      state.has_notified = response.data.has_notified;
      state.published_at = response.data.published_at ? dateShort(response.data.published_at) : null;
    }

    console.log(state);

    isLoading.value = false;
  };

  const handleSubmit = () => {
    updateContent();
  };

  const updateContent = async () => {
    errors.clear();
    isLoading.value = true;

    const content = JSON.stringify({
      en: state.contentEn,
      mm: state.contentMm,
    });

    const title = JSON.stringify({
      en: state.title.en,
      mm: state.title.mm,
    });

    var formData = new FormData();
    formData.append("id", route.params.id);

    if (fileUrl.value.length != 0) {
      formData.append("image", fileUrl.value);
    }

    console.log(state);

    formData.append("title", title || "");
    formData.append("content", content);
    formData.append("category_id", state.categoryId || "");
    formData.append("status", state.status || "");
    formData.append("published_at", state.published_at ? dateShort(state.published_at) : null);
    formData.append("is_notifiable", state.is_notifiable ? 1 : 0);
    formData.append("has_notified", state.has_notified ? 1 : 0);
    formData.append("_method", "PUT");

    try {
      await contentStore.updateContentAction(route.params.id, formData);

      const response = contentStore.getUpdateContent;

      if (response) {
        router.push({ name: "contentList" });
        EventBus.emit("show-toast", {
          severity: "success",
          summary: "",
          detail: response.message,
        });
      }

      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      if (error.status === 422) {
        const err = error.data.data;
        errors.record(err);
      }
    }
  };

  const takePicture = async () => {
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      photo.value = image.webPath;
      showCropDialog.value = true;
    } catch (err) {
      console.log(err.message);
    }
  };

  const cropImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_photo.value = canvas.toDataURL("image/jpeg");
    canvas.toBlob(function (blob) {
      fileUrl.value = blob;
    });
  };

  const showConfirmDialog = (id) => {
    confirm.require({
      message: "Are you sure?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yes, delete it",
      rejectLabel: "Cancel",
      acceptClass: "p-button-danger",
      rejectClass: "p-button-danger p-button-text",
      accept: () => {
        deleteContent(id);
      },
      reject: () => {
        //callback to execute when user rejects the action
      },
      onHide: () => {
        //Callback to execute when dialog is hidden
      },
    });
  };

  const deleteContent = async (id) => {
    isLoading.value = true;
    await contentStore.deleteContentAction({ id: id });

    const response = contentStore.getDeleteContent;

    if (response) {
      router.push({ name: "contentList" });
      EventBus.emit("show-toast", {
        severity: "success",
        summary: "",
        detail: response.message,
      });
    }

    isLoading.value = false;
  };

  return {
    isLoading,
    state,
    submitted,
    errors,
    handleSubmit,
    optionsStatus,
    optionsCategory,
    showConfirmDialog,
    crop_photo,
    cropImage,
    showCropDialog,
    takePicture,
    photo,
  };
};
